const API_URL = process.env.REACT_APP_API_URL

const getImageFullUrl = path => {
  if (!path) return null
  if (path.includes("http")) return path
  return `${API_URL}${path}`
}

const getImageOriginal = path => {
  if (!path) return null
  if (path.includes("http")) return path
  return path.replace(API_URL, "")
}

export { getImageFullUrl, getImageOriginal }
