//LOGIN
export const POST_LOGIN = "/home/loginMarketPlace"

//BLOG GENERATION
export const POST_BLOG_GEN = "/invastor_ai/generateAiBlog"

//GET AI BLOGS PAGINATED
export const POST_GET_AI_BLOGS_PAGINATED = "/blog/getAiBlogs"

//GET AI BLOGS PAGINATED
export const POST_GET_AI_BLOG_BY_ID = "/blog/getAiBlogById"
