import {
  GENERATE_BLOG,
  GENERATE_BLOG_SUCCESSFUL,
  GENERATE_BLOG_FAILED,
  GET_BLOGS_PAGINATED,
  GET_BLOGS_PAGINATED_SUCCESSFUL,
  GET_BLOGS_PAGINATED_FAILED,
  GET_BLOG_BY_ID,
  GET_BLOG_BY_ID_SUCCESSFUL,
  GET_BLOG_BY_ID_FAILED,
} from "./actionTypes"

export const generateBlog = prompt => {
  return {
    type: GENERATE_BLOG,
    payload: { prompt },
  }
}

export const generateBlogSuccessful = prompt => {
  return {
    type: GENERATE_BLOG_SUCCESSFUL,
    payload: prompt,
  }
}

export const generateBlogFailed = prompt => {
  return {
    type: GENERATE_BLOG_FAILED,
    payload: prompt,
  }
}

export const getBlogsPaginated = (page, limit) => {
  return {
    type: GET_BLOGS_PAGINATED,
    payload: { page, limit },
  };
};

export const getBlogsPaginatedSuccessful = page => {
  return {
    type: GET_BLOGS_PAGINATED_SUCCESSFUL,
    payload: page,
  }
}

export const getBlogsPaginatedFailed = page => {
  return {
    type: GET_BLOGS_PAGINATED_FAILED,
    payload: page,
  }
}

export const getBlogById = id => {
  return {
    type: GET_BLOG_BY_ID,
    payload: id,
  };
};

export const getBlogByIdSuccessful = id => {
  return {
    type: GET_BLOG_BY_ID_SUCCESSFUL,
    payload: id,
  }
}

export const getBlogByIdFailed = id => {
  return {
    type: GET_BLOG_BY_ID_FAILED,
    payload: id,
  }
}
