import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data)

// Blog Generation Method
const postBlogGen = data => post(url.POST_BLOG_GEN, data)

// Get AI Blogs Paginated
const getAiBlogsPaginated = (page, limit) => { 
  return get(url.POST_GET_AI_BLOGS_PAGINATED+`?page=${page}&limit=${limit}`);
}

// Get AI Blogs Paginated
const getBlogById = id => { 
  return get(url.POST_GET_AI_BLOG_BY_ID+`?id=${id}`);
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  postBlogGen,
  getAiBlogsPaginated,
  getBlogById
}
