import { del, get, post, put } from "./api"

const PREFIX = "message"

export const getChatsPaginated = data =>
  post(`${PREFIX}/getChatsPaginatedByUserId`, data)

export const getMessagesPaginated = data =>
  post(`${PREFIX}/getMessagesPaginated`, data)

export const sendMessageToAI = (id, parent, message, sender_id) =>
  post(`${PREFIX}/${id}/sendMessageToAI`, { parent, message, sender_id })

export const createMessageWithAI = (message, sender_id) =>
  post(`${PREFIX}/createMessageWithAI`, { message, sender_id })
