import React from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  FormFeedback
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { generateBlog } from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

import BlogGenPreview from "./BlogPreview";

import {
  getLoggedInUser
} from "../../helpers/requests_helper";

const BlogGenerator = () => {

  //meta title
  document.title="AI Blog Generator | Invastor";
  const { loading, content } = useSelector((state) => state.BlogGenerator);
  const generatedBlog = content?.blog;

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      // topic: 'Benefits of Meditation',
      // description: 'Write a blog post that explains the benefits of meditation for overall health and well-being. Include scientific research that supports the positive effects of meditation on mental health, physical health, and stress reduction. Also, provide tips and guidance for beginners who are interested in starting a meditation practice.',
      // tone: 'Informative and accessible, with a positive and encouraging tone that inspires readers to try meditation for themselves.',
      topic: '',
      description: '',
      tone: '',
    },
    validationSchema: Yup.object({
      topic: Yup.string().required("Please Enter A Topic"),
      description: Yup.string().required("Please Enter A Description"),
      tone: Yup.string().required("Please Enter A Tone"),
    }),
    onSubmit: (values, { resetForm }) => {
      const user = getLoggedInUser();
      const data = { user: user.userid, ...values}
      dispatch(generateBlog(data));
      resetForm();
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Blog" breadcrumbItem="AI Blog Generator" />
          <Row>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <CardTitle className="h5">Blog Topic</CardTitle>
                    <div className="form-floating mb-3">
                      {/* <Label className="form-label">e.g. "Rediscovering Adventure"</Label> */}
                      <Input 
                        type="text"
                        name="topic"
                        className="form-control" 
                        id="floatingnameInput" 
                        placeholder="Enter Name" 
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.topic || ""}
                        invalid={
                          validation.touched.topic && validation.errors.topic ? true : false
                        }
                        disabled={loading}
                      />
                      <label style={{ whiteSpace: 'normal'}} htmlFor="floatingemailInput">e.g. "Rediscovering Adventure"</label>
                      {validation.touched.topic && validation.errors.topic ? (
                        <FormFeedback type="invalid">{validation.errors.topic}</FormFeedback>
                      ) : null}
                    </div>

                    <CardTitle className="h5">Blog Description</CardTitle>
                    <div className="form-floating mb-3">
                      <Input
                        type="textarea"
                        name="description"
                        className="form-control" 
                        id="floatingemailInput" 
                        placeholder="Enter Email address"
                        style={{height: '10rem', paddingTop: '2.8rem'}}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description && validation.errors.description ? true : false
                        }
                        disabled={loading}
                      />
                      <label style={{ whiteSpace: 'normal'}} htmlFor="floatingemailInput">e.g. "Embark on journeys that rejuvenate the soul"</label>
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>

                    <CardTitle className="h5">Tone</CardTitle>
                    <div className="form-floating mb-3">
                      <Input 
                        type="text"
                        name="tone"
                        className="form-control" 
                        id="floatingnameInput" 
                        placeholder="Enter Name" 
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tone || ""}
                        invalid={
                          validation.touched.tone && validation.errors.tone ? true : false
                        }
                        disabled={loading}
                      />
                      <label htmlFor="floatingnameInput">e.g. "Friendly"</label>
                      {validation.touched.tone && validation.errors.tone ? (
                        <FormFeedback type="invalid">{validation.errors.tone}</FormFeedback>
                      ) : null}
                    </div>
                    {loading ? 
                      (
                        <div>
                          <button disabled type="submit" className="btn btn-primary w-md">Generating Blog...</button>
                        </div>
                      ) 
                      : 
                      (
                        <div>
                          <button type="submit" className="btn btn-primary w-md">Create Blog</button>
                        </div>
                      )
                    }
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Container fluid={true}>
            {!loading && generatedBlog && (
              <BlogGenPreview
                id={generatedBlog._id}
                user={generatedBlog.user}
                topic={generatedBlog.topic}
                date={generatedBlog.createdAt}
                text={generatedBlog.content}
              />
            )}
            </Container>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogGenerator;