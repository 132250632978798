import {
  GET_GROUPS_SUCCESS,
  GET_CHATS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_CHATS_FAIL,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,

  // REAL ACTIONS:
  GET_CHATS_PAGINATED,
  GET_CHATS_PAGINATED_SUCCESSFUL,
  GET_CHATS_PAGINATED_FAILED,
  GET_MESSAGES_PAGINATED,
  GET_MESSAGES_PAGINATED_SUCCESSFUL,
  GET_MESSAGES_PAGINATED_FAILED,
} from "./actionTypes"

const INIT_STATE = {
  groups: [],
  contacts: [],
  error: {},
  // REAL STATE:
  loading: false,
  pageChats: 1,
  pageSizeChats: 10,
  totalChats: 0,
  chats: [],
  hasMoreChats: null,
  profileAiData: null,
  pageMessages: 1,
  pageSizeMessages: 150,
  totalMessages: 0,
  messages: [],
  hasMoreMessages: null,
}

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload,
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      }

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
      }

    case GET_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }

    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // REAL ACTIONS:
    case GET_CHATS_PAGINATED:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_CHATS_PAGINATED_SUCCESSFUL:
      return {
        ...state,
        chats: action.payload.chats,
        hasMoreChats: action.payload.hasMore,
        totalChats: action.payload.totalChats,
        profileAiData: action.payload.profileAi,
        loading: false,
        error: null,
      }
    case GET_CHATS_PAGINATED_FAILED:
      return {
        ...state,
        chats: [],
        hasMoreChats: false,
        totalChats: 0,
        profileAiData: null,
        loading: false,
        error: action.payload,
      }
    case GET_MESSAGES_PAGINATED:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_MESSAGES_PAGINATED_SUCCESSFUL:
      return {
        ...state,
        totalMessages: action.payload.totalMessages,
        hasMoreMessages: action.payload.hasMore,
        messages: action.payload.messages.message,
        loading: false,
        error: null,
      }
    case GET_MESSAGES_PAGINATED_FAILED:
      return {
        ...state,
        totalMessages: 0,
        messages: [],
        hasMoreMessages: false,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Calendar
