import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap"

// components
import MessagesList from "./MessagesList"

const Messages = ({
  Chat_Box_Username,
  Chat_Box_User_Status,
  search_Menu,
  toggleSearch,
  settings_Menu,
  toggleSettings,
  other_Menu,
  toggleOther,
  messages,
  curMessage,
  onKeyPress,
  setMessageBox,
  currentUser,
  setcurMessage,
  addMessage,
  copyMsg,
  toggle_deleMsg,
  fetching,
  placeholderToInput,
  newMessageCreated,
  idChatWithAi,
  setMessageList,
  currentRoomId,
}) => {
  return (
    <div className="w-100 user-chat">
      <Card>
        <div className="p-4 border-bottom ">
          <Row>
            <Col md="4" xs="9">
              <h5 className="font-size-15 mb-1">{Chat_Box_Username}</h5>

              <p className="text-muted mb-0">
                <i
                  className={
                    Chat_Box_User_Status === "Active Now"
                      ? "mdi mdi-circle text-success align-middle me-2"
                      : Chat_Box_User_Status === "intermediate"
                      ? "mdi mdi-circle text-warning align-middle me-1"
                      : "mdi mdi-circle align-middle me-1"
                  }
                />
                {Chat_Box_User_Status}
              </p>
            </Col>
            <Col md="8" xs="3">
              <ul className="list-inline user-chat-nav text-end mb-0">
                <li className="list-inline-item d-none d-sm-inline-block">
                  <Dropdown
                    className="me-1"
                    isOpen={search_Menu}
                    toggle={toggleSearch}
                  >
                    <DropdownToggle className="btn nav-btn" tag="a">
                      <i className="bx bx-search-alt-2" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md">
                      <Form className="p-3">
                        <FormGroup className="m-0">
                          <InputGroup>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search ..."
                              aria-label="Recipient's username"
                            />
                            <Button color="primary" type="submit">
                              <i className="mdi mdi-magnify" />
                            </Button>
                          </InputGroup>
                        </FormGroup>
                      </Form>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li className="list-inline-item d-none d-sm-inline-block">
                  <Dropdown
                    isOpen={settings_Menu}
                    toggle={toggleSettings}
                    className="me-1"
                  >
                    <DropdownToggle className="btn nav-btn" tag="a">
                      <i className="bx bx-cog" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem href="#">View Profile</DropdownItem>
                      <DropdownItem href="#">Clear chat</DropdownItem>
                      <DropdownItem href="#">Muted</DropdownItem>
                      <DropdownItem href="#">Delete</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li className="list-inline-item">
                  <Dropdown isOpen={other_Menu} toggle={toggleOther}>
                    <DropdownToggle className="btn nav-btn" tag="a">
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem href="#">Action</DropdownItem>
                      <DropdownItem href="#">Another Action</DropdownItem>
                      <DropdownItem href="#">Something else</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <MessagesList
          setMessageBox={setMessageBox}
          messages={messages}
          curMessage={curMessage}
          onKeyPress={onKeyPress}
          currentUser={currentUser}
          setcurMessage={setcurMessage}
          addMessage={addMessage}
          copyMsg={copyMsg}
          toggle_deleMsg={toggle_deleMsg}
          fetching={fetching}
          placeholderToInput={placeholderToInput}
          newMessageCreated={newMessageCreated}
          idChatWithAi={idChatWithAi}
          setMessageList={setMessageList}
          currentRoomId={currentRoomId}
        />
      </Card>
    </div>
  )
}

export default Messages
