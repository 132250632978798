import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import {
  GET_CHATS,
  GET_CONTACTS,
  GET_GROUPS,
  GET_MESSAGES,
  POST_ADD_MESSAGE,
  // REAL ACTION TYPES
  GET_CHATS_PAGINATED,
  GET_MESSAGES_PAGINATED,
} from "./actionTypes"
import {
  getChatsSuccess,
  getChatsFail,
  getGroupsSuccess,
  getGroupsFail,
  getContactsSuccess,
  getContactsFail,
  getMessagesSuccess,
  getMessagesFail,
  addMessageSuccess,
  addMessageFail,

  // REAL ACTIONS
  getChatsPaginatedSuccessful,
  getChatsPaginatedFailed,
  getMessagesPaginatedSuccessful,
  getMessagesPaginatedFailed,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getChats,
  getGroups,
  getContacts,
  getMessages,
  addMessage,
} from "../../helpers/fakebackend_helper"

import { getChatsPaginated, getMessagesPaginated } from "store/services/chat"

function* onGetChats() {
  try {
    const response = yield call(getChats)
    yield put(getChatsSuccess(response))
  } catch (error) {
    yield put(getChatsFail(error))
  }
}

function* onGetGroups() {
  try {
    const response = yield call(getGroups)
    yield put(getGroupsSuccess(response))
  } catch (error) {
    yield put(getGroupsFail(error))
  }
}

function* onGetContacts() {
  try {
    const response = yield call(getContacts)
    yield put(getContactsSuccess(response))
  } catch (error) {
    yield put(getContactsFail(error))
  }
}

function* onGetMessages({ roomId }) {
  try {
    const response = yield call(getMessages, roomId)
    yield put(getMessagesSuccess(response))
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}

function* onAddMessage({ message }) {
  try {
    const response = yield call(addMessage, message)
    yield put(addMessageSuccess(response))
  } catch (error) {
    yield put(addMessageFail(error))
  }
}

// REAL REQUESTS

function* onGetChatsPaginated({ payload: { page } }) {
  try {
    // TODO: get pageSize from reducer
    const pageSize = 10
    const pagination = {
      page,
      pageSize,
      total: 0,
    }
    const response = yield call(getChatsPaginated, pagination)
    yield put(getChatsPaginatedSuccessful(response))
  } catch (error) {
    console.log("There was an error get the chats: ", error)
    yield put(getChatsPaginatedFailed(error))
  }
}

function* onGetMessagesPaginated({ payload }) {
  try {
    const { page, id } = payload
    if (!!id && !isNaN(id) && Math.sign(id) == 1) {
      // TODO: get pageSize from reducer
      const pageSize = 150
      const pagination = {
        page,
        pageSize,
        total: 0,
      }
      const response = yield call(getMessagesPaginated, { pagination, id })
      yield put(getMessagesPaginatedSuccessful(response))
    }
  } catch (error) {
    console.log("There was an error get the messages: ", error)
    yield put(getMessagesPaginatedFailed(error))
  }
}

function* chatSaga() {
  yield takeEvery(GET_CHATS, onGetChats)
  yield takeEvery(GET_GROUPS, onGetGroups)
  yield takeEvery(GET_CONTACTS, onGetContacts)
  yield takeEvery(GET_MESSAGES, onGetMessages)
  yield takeEvery(POST_ADD_MESSAGE, onAddMessage)

  // REAL REQUESTS
  yield takeEvery(GET_CHATS_PAGINATED, onGetChatsPaginated)
  yield takeEvery(GET_MESSAGES_PAGINATED, onGetMessagesPaginated)
}

export default chatSaga
