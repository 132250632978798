import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getImageFullUrl } from "common/utils/urlParser"
import { findChatWithAi, sliceTheLastMessage } from "./utils"
import moment from "moment"

const ChatWithAi = ({
  userChatOpen,
  currentRoomId,
  chat,
  lastMessageAi,
  setLastMessageAi,
  lastMessage,
  setLastMessage,
  lastMessageTime,
  setLastMessageTime,
}) => {
  const [canTalkWithAi, setCanTalkWithAi] = useState(false)
  const [profileAiData, setProfileAiData] = useState({})
  const [profileImgAi, setProfileImgAi] = useState("")
  const [chatWithAiId, setChatWithAiId] = useState({})

  useEffect(() => {
    const loadDataFromAi = async () => {
      const { profileAiData, chats } = chat
      if (!!profileAiData) {
        setCanTalkWithAi(true)
        setProfileImgAi(await getImageFullUrl(profileAiData.profileImg))
        setProfileAiData(profileAiData)
      }
      if (!!chats && chats.length > 0) {
        const chatWithAifinded = findChatWithAi(chats)
        setChatWithAiId(chatWithAifinded)
        setLastMessage(
          sliceTheLastMessage(chatWithAifinded?.message[0]?.message || "")
        )
        setLastMessageTime(moment(chatWithAifinded.lastMessage).fromNow())
      }
    }

    loadDataFromAi()
  }, [chat])

  return (
    <>
      {canTalkWithAi && (
        <>
          <h5 className="font-size-14 mb-3">Chat With Ai</h5>
          <li
            key={(chatWithAiId?._id || -1) + "online"}
            className={currentRoomId === chatWithAiId?._id ? "active" : ""}
          >
            <Link
              to="#"
              onClick={() => {
                userChatOpen(
                  profileAiData._id,
                  profileAiData.first_name + " " + profileAiData.last_name,
                  // Always online talk with the ai
                  "online",
                  chatWithAiId?._id || -1,
                  true
                )
              }}
            >
              <div className="d-flex">
                <div className="align-self-center me-3">
                  <i
                    className={
                      // Always online talk with the ai
                      "online" === "online"
                        ? "mdi mdi-circle text-success font-size-10"
                        : null === "intermediate"
                        ? "mdi mdi-circle text-warning font-size-10"
                        : "mdi mdi-circle font-size-10"
                    }
                  />
                </div>
                <div className="align-self-center me-3">
                  <img
                    src={profileImgAi}
                    className="rounded-circle avatar-xs"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-14 mb-1">
                    {profileAiData.first_name + " " + profileAiData.last_name}
                  </h5>
                  <p className="text-truncate mb-0">{lastMessage}</p>
                </div>
                <div className="font-size-11">{lastMessageTime}</div>
              </div>
            </Link>
          </li>
        </>
      )}
    </>
  )
}

export default connect(state => {
  return {
    chat: state.chat,
  }
})(ChatWithAi)
