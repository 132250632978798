const REACT_APP_OPENAI_ID_USER = process.env.REACT_APP_OPENAI_ID_USER

export const findChatWithAi = chats => {
  return (chats || []).find(
    chat => chat?.reciver_id?._id == REACT_APP_OPENAI_ID_USER
  )
}

export const sliceTheLastMessage = message => {
  const lastMessage =
    message.length > 30 ? message?.slice(0, 30) + "..." : message
  return lastMessage || "Empty message"
}

export const scrollDownTheMessages = () => {
  const messages = document.getElementById("messages")
  messages.scrollTop = messages.scrollHeight
}
