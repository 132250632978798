import React from 'react';
import { Col, Card } from 'reactstrap';
import { Link } from "react-router-dom";

import img1 from "../../assets/images/small/img-2.jpg";
//import images
import small from "../../assets/images/small/img-2.jpg";
import small2 from "../../assets/images/small/img-6.jpg";

function BlogCard(props) {
  
  function extractIntroduction(text) {
    const introIndex = text.indexOf(`Introduction:<br>`);
    
    if (introIndex !== -1) {
      const introContent = text.substring(introIndex + "Introduction:<br>".length);      
      const formattedIntro = introContent.replace(/<br>/g, " ");
      
      return formattedIntro.trim().slice(0, 300)+`...`;
    }
    
    return "";
  }

  const { 
    blog,
    list = true 
  } = props;

  const title= blog.topic
  const date= blog.createdAt
  const text= blog.content
  const id= blog._id
  const imgSrc= blog.imgSrc
  
  const contentPreview = extractIntroduction(text);
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  blog.createdAt = formattedDate;

  return (
    <Col {...(list ? {} : { sm: 6 })}>
      <Card className={ list ? "" : "p-1 border shadow-none"}>
        <div className={ list ? "" : "p-3"}>
          <h5>
            <Link 
              to={{ pathname: `/blog/${id}` }} 
              state= {{blog}} 
              className="text-dark"
            >
              {title}
            </Link>
          </h5>
          <p className="text-muted">{formattedDate}</p>

          <div className="position-relative mb-3">
            <Link to={{ pathname: `/blog/${id}` }} 
              state= {{blog}} 
            >
              <img src={small} alt="" className="img-thumbnail" />
            </Link>
          </div>

          <ul className="list-inline">
            <li className="list-inline-item mr-3">
              <Link to="#" className="text-muted">
                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                Project
              </Link>
            </li>
            <li className="list-inline-item mr-3">
              <Link to="#" className="text-muted">
                <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                12 Comments
              </Link>
            </li>
          </ul>
          <p>{list ? contentPreview : contentPreview.slice(0, 150)+`...`}</p>

          <div>
            <Link 
              to={{ pathname: `/blog/${id}` }} 
              state= {{blog}} 
              className="text-primary"
            >
              Read more <i className="mdi mdi-arrow-right"></i>
            </Link>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default BlogCard;
