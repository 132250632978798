import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Col, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import BlogCard from "./BlogCard";
import { getBlogsPaginated } from "../../store/actions";

const BlogView = ({ view = "list" }) => {
  const dispatch = useDispatch();
  const [activeTab, toggleTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { blogs = [], totalBlogs = 0 } = useSelector(state => state.BlogGenerator.blogs) || {};

  const totalPages = Math.ceil(totalBlogs / itemsPerPage);

  useEffect(() => {
    dispatch(getBlogsPaginated(currentPage, itemsPerPage));
  }, [dispatch, currentPage]);

  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab);
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  let rows = [];

  if (blogs) {
    rows = blogs.reduce((acc, curr, i) => {
      if (i % 2 === 0) {
        acc.push([curr]);
      } else {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    }, []);
  }

  return (
    <Col xl={9} lg={8}>
      <Card>
        {/* Tabs */}
        {/* ... */}
        <TabContent className="p-4" activeTab={activeTab}>
          <TabPane tabId="1">
            <div>
              <Row className="justify-content-center">
                <Col xl={8}>
                  <div>
                    <div className="row align-items-center">
                      <div className="col-4">
                        {/* ... */}
                      </div>

                      <Col xs={8}>
                        <div className="float-end">
                          <ul className="nav nav-pills">
                            <NavItem>
                              <NavLink className="disabled" to="#" tabIndex="-1">
                                View :
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <Link className={`nav-link ${view === "list" ? "active" : ""}`} to="/blog-list">
                                <i className="mdi mdi-format-list-bulleted"></i>
                              </Link>
                            </NavItem>
                            <NavItem>
                              <Link className={`nav-link ${view === "grid" ? "active" : ""}`} to="/blog-grid">
                                <i className="mdi mdi-view-grid-outline"></i>
                              </Link>
                            </NavItem>
                          </ul>
                        </div>
                      </Col>
                    </div>

                    <hr className="mb-4" />

                    {view === "list" ? (
                      // Render list view
                      blogs && blogs.length > 0 ? (
                        blogs.map(blog => (
                          <BlogCard
                            key={blog._id}
                            blog={blog}
                          />
                        ))
                      ) : (
                        <p>No blogs available.</p>
                      ))
                      : (
                      // Render grid view
                      rows.map((row, i) => (
                        <Row key={i}>
                          {row.map((blog, j) => (
                            <BlogCard
                              key={j}
                              blog={blog}
                              list={false}
                            />
                          ))}
                        </Row>
                      ))
                    )}

                    <hr className="my-4" />

                    <div className="text-center">
                      <ul className="pagination justify-content-center pagination-rounded">
                        {Array.from({ length: totalPages }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                          >
                            <Link
                              to="#"
                              className="page-link"
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="2">
            {/* Archive section */}
            {/* ... */}
          </TabPane>
        </TabContent>
      </Card>
    </Col>
  );
};

export default BlogView;
