import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { postSocialLogin } from "../../../helpers/fakebackend_helper"

import { postJwtLogin } from "../../../helpers/requests_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    })
    localStorage.setItem("token", response.token)
    localStorage.setItem("user", JSON.stringify(response.decodedToken))
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    history("/dashboard")
  } catch (error) {
    const errorMessage =
      error.response.status === 401
        ? "Invalid email or password"
        : "Something went wrong"
    yield put(apiError(errorMessage))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("authUser")
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const response = yield call(postSocialLogin, data)
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))

    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
