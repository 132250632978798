import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import Breadcrumbs from "components/Common/Breadcrumb"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import {
  addMessage as onAddMessage,
  getChats as onGetChats,
  getContacts as onGetContacts,
  getGroups as onGetGroups,
  getMessages as onGetMessages,
  // REAL REQUESTS
  getChatsPaginated,
  getMessagesPaginated,
} from "store/actions"
import { useSelector, useDispatch, connect } from "react-redux"
import ChatWithAi from "components/Elements/Chat/ChatWithAi/ChatWithAi"
import Messages from "components/Elements/Chat/Messages"
import { getLoggedInUser } from "../../helpers/requests_helper"
import moment from "moment"
import {
  findChatWithAi,
  sliceTheLastMessage,
} from "components/Elements/Chat/ChatWithAi/utils"
import { createMessageWithAI, sendMessageToAI } from "store/services/chat"

const REACT_APP_OPENAI_ID_USER = process.env.REACT_APP_OPENAI_ID_USER

const Chat = ({ chat }) => {
  //meta title
  document.title = "Chat | Invastor"

  const dispatch = useDispatch()

  const { chats, groups, contacts, messages } = useSelector(state => ({
    chats: state.chat.chats,
    groups: state.chat.groups,
    contacts: state.chat.contacts,
    messages: state.chat.messages,
  }))
  const [messageBox, setMessageBox] = useState(null)
  const [currentRoomId, setCurrentRoomId] = useState(null)
  const [currentUser, setCurrentUser] = useState({
    isActive: true,
  })
  const [menu1, setMenu1] = useState(false)
  const [search_Menu, setsearch_Menu] = useState(false)
  const [settings_Menu, setsettings_Menu] = useState(false)
  const [other_Menu, setother_Menu] = useState(false)
  const [activeTab, setactiveTab] = useState("1")
  const [Chat_Box_Username, setChat_Box_Username] = useState("")
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("Active Now")
  const [curMessage, setcurMessage] = useState("")
  // To send to the ai
  const [lastMessageAi, setLastMessageAi] = useState("")
  const [lastMessage, setLastMessage] = useState("Talk with me")
  const [lastMessageTime, setLastMessageTime] = useState("")
  const [chatingWithAi, setChatingWithAi] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [placeholderToInput, setPlaceholderToInput] =
    useState("Enter Message...")
  const [messageList, setMessageList] = useState([])
  const [newMessageCreated, setNewMessageCreated] = useState(false)
  const [idChatWithAi, setIdChatWithAi] = useState(null)

  useEffect(() => {
    // dispatch(onGetChats())
    dispatch(onGetGroups())
    dispatch(onGetContacts())
    dispatch(onGetMessages(currentRoomId))
  }, [
    // onGetChats,
    onGetGroups,
    onGetContacts,
    onGetMessages,
    currentRoomId,
  ])

  useEffect(() => {
    if (!isEmpty(messages)) {
      scrollToBottom()
      setMessageList(messages.reverse())
    }
  }, [messages])

  useEffect(() => {
    if (!isEmpty(chats) && !idChatWithAi) {
      const chatWithAifinded = findChatWithAi(chats)
      if (!!chatWithAifinded) {
        const { _id } = chatWithAifinded
        dispatch(getMessagesPaginated({ page: 1, id: _id }))
        setIdChatWithAi(_id)
      }
    }
  }, [chats])

  useEffect(() => {
    const userLogged = getLoggedInUser()
    if (!!userLogged) {
      const name = userLogged.first_name + " " + userLogged.last_name
      setCurrentUser({ ...userLogged, name, isActive: true })
    }
  }, [])

  useEffect(() => {
    const { profileAiData } = chat
    if (!!profileAiData && !Chat_Box_Username) {
      setChat_Box_Username(
        profileAiData.first_name + " " + profileAiData.last_name
      )
    }
  }, [chat])

  useEffect(() => {
    dispatch(getChatsPaginated(1))
  }, [dispatch])

  // const toggleNotification = () => {
  //   setnotification_Menu(!notification_Menu)
  // }

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu)
  }

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu)
  }

  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  //Use For Chat Box
  const userChatOpen = (id, name, status, roomId, isChatWithAi) => {
    setChat_Box_Username(name)
    setCurrentRoomId(roomId)
    if (isChatWithAi) {
      setChatingWithAi(true)
      dispatch(getMessagesPaginated({ page: 1, id: roomId }))
    } else {
      setChatingWithAi(false)
      dispatch(onGetMessages(roomId))
    }
  }

  const addMessage = async (roomId, sender) => {
    if (chatingWithAi) {
      await addMessageToAi()
    } else {
      const message = {
        id: Math.floor(Math.random() * 100),
        roomId,
        sender,
        message: curMessage,
        createdAt: new Date(),
      }
      setcurMessage("")
      dispatch(onAddMessage(message))
    }
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }

  const addMessageToAi = async () => {
    try {
      if (!!curMessage && curMessage.trim() != "") {
        const now = moment()
        let hours = now.hour() + ":" + now.minutes()
        hours = hours + (now.hour() >= 12 ? " PM" : " AM")
        const { userid } = currentUser
        const newMessageToMessages = {
          attachments: [],
          created: now.format("DD MM YYYY hh:mm:ss"),
          deleteBy: [],
          imgSrc: "",
          isAdmin: false,
          message: curMessage,
          message_type: "text",
          owner: userid,
          seenBy: [REACT_APP_OPENAI_ID_USER],
          time: hours,
        }
        messageList.unshift(newMessageToMessages)
        setMessageList(messageList)
        setNewMessageCreated(true)
        setLastMessage(sliceTheLastMessage(curMessage))
        setLastMessageTime(moment(moment.now()).fromNow())
        setcurMessage("")
        setPlaceholderToInput("Awaiting to AI response...")
        setFetching(true)
        if (!!idChatWithAi) {
          const parent = messageList[1]
          const { message: parentMessage } = parent
          const parentData = {
            message: parentMessage,
            time: moment().format("LT"),
          }
          const messageData = {
            message: curMessage,
            time: moment().format("LT"),
          }
          const res = await sendMessageToAI(
            idChatWithAi,
            parentData,
            messageData,
            userid
          )
          const { message: newMessages } = res.message
          const lastNewMessage = newMessages.slice(-1).pop()
          const { message: lastNewMessageText } = lastNewMessage
          setLastMessage(sliceTheLastMessage(lastNewMessageText))
          setLastMessageTime(moment(moment.now()).fromNow())
          setMessageList(newMessages.reverse())
          setNewMessageCreated(false)
          setPlaceholderToInput("Type your message")
          setFetching(false)
        } else {
          const res = await createMessageWithAI(curMessage, userid)
          const { message: newMessages, _id } = res.message
          const lastNewMessage = newMessages.slice(-1).pop()
          const { message: lastNewMessageText } = lastNewMessage
          setIdChatWithAi(_id)
          setLastMessage(sliceTheLastMessage(lastNewMessageText))
          setLastMessageTime(moment(moment.now()).fromNow())
          setMessageList(newMessages.reverse())
          setNewMessageCreated(false)
          setPlaceholderToInput("Type your message")
          setFetching(false)
        }
      } else {
        // TODO: show the new notification
        console.log("You can send a text empty.")
        // modalNotification("warning", "You can send a text empty.")
      }
    } catch (error) {
      console.log(error)
      // modalNotification("error", "Something went wrong trying to chat with AI")
      setFetching(false)
      setPlaceholderToInput("Type your message")
    }
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurMessage(value)
      addMessage(currentRoomId, currentUser.name)
    }
  }

  //serach recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue
    input = document.getElementById("search-user")
    filter = input.value.toUpperCase()
    ul = document.getElementById("recent-list")
    li = ul.getElementsByTagName("li")
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0]
      txtValue = a.textContent || a.innerText
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = ""
      } else {
        li[i].style.display = "none"
      }
    }
  }

  const [deleteMsg, setDeleteMsg] = useState("")
  const toggle_deleMsg = ele => {
    setDeleteMsg(!deleteMsg)
    ele.closest("li").remove()
  }

  const copyMsg = ele => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Invastor" breadcrumbItem="Chat" />
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    <div className="py-4 border-bottom">
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <img
                            src={avatar1}
                            className="avatar-xs rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-15 mt-0 mb-1">
                            {currentUser.first_name +
                              " " +
                              currentUser.last_name}
                          </h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-2" />
                            Active
                          </p>
                        </div>

                        <div>
                          <Dropdown
                            isOpen={menu1}
                            toggle={() => setMenu1(!menu1)}
                            className="chat-noti-dropdown active"
                          >
                            <DropdownToggle tag="a" className="btn">
                              <i className="bx bx-bell bx-tada"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem href="#">Action</DropdownItem>
                              <DropdownItem href="#">
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#">
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Chat</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Groups</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3")
                            }}
                          >
                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Contacts</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            <ul
                              className="list-unstyled chat-list"
                              id="recent-list"
                            >
                              <PerfectScrollbar style={{ height: "410px" }}>
                                <ChatWithAi
                                  chats={chats}
                                  userChatOpen={userChatOpen}
                                  currentRoomId={currentRoomId}
                                  lastMessageAi={lastMessageAi}
                                  setLastMessageAi={setLastMessageAi}
                                  lastMessage={lastMessage}
                                  setLastMessage={setLastMessage}
                                  lastMessageTime={lastMessageTime}
                                  setLastMessageTime={setLastMessageTime}
                                />
                                {/* <h5 className="font-size-14 mb-3 mt-3">
                                  Recent
                                </h5>
                                {map(chats, chat => (
                                  <li
                                    key={chat.id + chat.status}
                                    className={
                                      currentRoomId === chat.roomId
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        userChatOpen(
                                          chat.id,
                                          chat.name,
                                          chat.status,
                                          chat.roomId
                                        )
                                      }}
                                    >
                                      <div className="d-flex">
                                        <div className="align-self-center me-3">
                                          <i
                                            className={
                                              chat.status === "online"
                                                ? "mdi mdi-circle text-success font-size-10"
                                                : chat.status === "intermediate"
                                                ? "mdi mdi-circle text-warning font-size-10"
                                                : "mdi mdi-circle font-size-10"
                                            }
                                          />
                                        </div>
                                        {chat.isImg ? (
                                          <div className="avatar-xs align-self-center me-3">
                                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                              {chat.profile}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="align-self-center me-3">
                                            <img
                                              src={chat.image}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>
                                        )}

                                        <div className="flex-grow-1 overflow-hidden">
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            {chat.name}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {chat.description}
                                          </p>
                                        </div>
                                        <div className="font-size-11">
                                          {chat.time}
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))} */}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>

                        <TabPane tabId="2">
                          <h5 className="font-size-14 mb-3">Group</h5>
                          <ul className="list-unstyled chat-list">
                            <PerfectScrollbar style={{ height: "410px" }}>
                              {groups &&
                                groups.map(group => (
                                  <li key={"test" + group.image}>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        userChatOpen(
                                          group.id,
                                          group.name,
                                          group.status,
                                          Math.floor(Math.random() * 100)
                                        )
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-xs me-3">
                                          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                            {group.image}
                                          </span>
                                        </div>

                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14 mb-0">
                                            {group.name}
                                          </h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                            </PerfectScrollbar>
                          </ul>
                        </TabPane>

                        <TabPane tabId="3">
                          <h5 className="font-size-14 mb-3">Contact</h5>

                          <div>
                            <PerfectScrollbar style={{ height: "410px" }}>
                              {contacts &&
                                contacts.map(contact => (
                                  <div
                                    key={"test_" + contact.category}
                                    className={
                                      contact.category === "A" ? "" : "mt-4"
                                    }
                                  >
                                    <div className="avatar-xs mb-3">
                                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                        {contact.category}
                                      </span>
                                    </div>

                                    <ul className="list-unstyled chat-list">
                                      {contact.child.map(array => (
                                        <li key={"test" + array.id}>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              userChatOpen(
                                                array.id,
                                                array.name,
                                                array.status,
                                                Math.floor(Math.random() * 100)
                                              )
                                            }}
                                          >
                                            <h5 className="font-size-14 mb-0">
                                              {array.name}
                                            </h5>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                            </PerfectScrollbar>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <Messages
                  Chat_Box_Username={Chat_Box_Username}
                  Chat_Box_User_Status={Chat_Box_User_Status}
                  search_Menu={search_Menu}
                  toggleSearch={toggleSearch}
                  settings_Menu={settings_Menu}
                  toggleSettings={toggleSettings}
                  other_Menu={other_Menu}
                  toggleOther={toggleOther}
                  messages={!!messageList ? messageList : messages}
                  curMessage={curMessage}
                  onKeyPress={onKeyPress}
                  setMessageBox={setMessageBox}
                  currentUser={currentUser}
                  setcurMessage={setcurMessage}
                  addMessage={addMessage}
                  copyMsg={copyMsg}
                  toggle_deleMsg={toggle_deleMsg}
                  fetching={fetching}
                  placeholderToInput={placeholderToInput}
                  newMessageCreated={newMessageCreated}
                  idChatWithAi={idChatWithAi}
                  setMessageList={setMessageList}
                  currentRoomId={currentRoomId}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  // onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

export default connect(state => {
  return {
    chat: state.chat,
  }
})(Chat)
