import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Blog Redux states
import { GENERATE_BLOG, GET_BLOGS_PAGINATED, GET_BLOG_BY_ID } from "./actionTypes"

import { 
  generateBlogSuccessful, 
  generateBlogFailed, 
  getBlogsPaginatedSuccessful, 
  getBlogsPaginatedFailed,
  getBlogByIdSuccessful,
  getBlogByIdFailed
} from "./actions"

import {
  postBlogGen,
  getAiBlogsPaginated,
  getBlogById
} from "../../../helpers/requests_helper";

function* onGenerateBlog({ payload: { prompt } }) {
  try {
    const response = yield call(postBlogGen, prompt)
    yield put(generateBlogSuccessful(response))
  } catch (error) {
    console.log("There was an error generating the blog: ", error)
    yield put(generateBlogFailed(error))
  }
}

function* onGetBlogsPaginated({ payload: { page, limit } }) {
  try {
    const response = yield call(getAiBlogsPaginated, page, limit)
    yield put(getBlogsPaginatedSuccessful(response))
  } catch (error) {
    console.log("There was an error fetching the blogs: ", error)
    yield put(getBlogsPaginatedFailed(error))
  }
}

function* onGetBlogById({ payload: id }) {
  try {
    const response = yield call(getBlogById, id)
    yield put(getBlogByIdSuccessful(response))
  } catch (error) {
    console.log("There was an error fetching the ai blog: ", error)
    yield put(getBlogByIdFailed(error))
  }
}

export function* watchBlogGeneration() {
  yield takeEvery(GENERATE_BLOG, onGenerateBlog)
  yield takeEvery(GET_BLOGS_PAGINATED, onGetBlogsPaginated)
  yield takeEvery(GET_BLOG_BY_ID, onGetBlogById)
}

function* blogGenSaga() {
  yield all([fork(watchBlogGeneration)])
}

export default blogGenSaga