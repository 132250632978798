import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Container,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getBlogById } from "../../store/actions";

// import images
import img1 from "../../assets/images/small/img-2.jpg";

import BlogGenPreview from "./BlogPreview";

const BlogDetail = (props) => {
  //meta title
  document.title="Blog Details | Invastor";
  const dispatch = useDispatch();
  const { blog } = useSelector(state => state.BlogGenerator.content) || {};
  const { state } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getBlogById(parseInt(id)));
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {blog && (
            <BlogGenPreview
              id={blog._id}
              user={blog.user}
              topic={blog.topic}
              date={blog.createdAt}
              text={blog.content}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogDetail
