import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import { scrollDownTheMessages } from "./ChatWithAi/utils"
import InfiniteScroll from "react-infinite-scroll-component"
import spinnerGif from "../../../assets/images/page-load-loader.gif"
import { connect } from "react-redux"
import { getMessagesPaginated } from "store/services/chat"
import autosize from "autosize";

const MessagesList = ({
  setMessageBox,
  messages,
  curMessage,
  onKeyPress,
  currentUser,
  setcurMessage,
  addMessage,
  copyMsg,
  toggle_deleMsg,
  fetching,
  placeholderToInput,
  newMessageCreated,
  chat,
  idChatWithAi,
  setMessageList,
  currentRoomId,
}) => {
  const [page, setPage] = useState(2)
  const [hasMore, setHasMore] = useState(null)
  const [nameAi, setNameAi] = useState(null)

  const fetchMessagesPaginated = async () => {
    const pagination = {
      page,
      pageSize: 150,
      total: 0,
    }
    try {
      const res = await getMessagesPaginated({ id: idChatWithAi, pagination })
      if (!!res.error) {
        throw new Error(
          "An error occurred getting the old messages. Please try again later."
        )
      }
      setHasMore(res.hasMore)
      setMessageList(messages.concat(res.messages.message.reverse()))
      setPage(page + 1)
    } catch (error) {
      console.log(error)
      // TODO: show the new notification
      // modalNotification(
      //   "error",
      //   "An error occurred getting the old messages. Please try again later."
      // )
    }
  }

  useEffect(() => {
    setTimeout(() => {
      scrollDownTheMessages()
    }, 10)
  }, [newMessageCreated])

  useEffect(() => {
    if (!!chat && chat.hasMoreMessages != null && hasMore == null) {
      setHasMore(chat.hasMoreMessages)
    }
    if (!!chat) {
      const { profileAiData } = chat
      if (!!profileAiData) {
        setNameAi(profileAiData.first_name + " " + profileAiData.last_name)
      }
    }
  }, [chat])

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      autosize(textareaRef.current);
    }
  }, []);

  return (
    <div>
      <div className="chat-conversation p-3">
        <div
          className="list-unstyled"
          id="messages"
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            // height: "470px"
          }}
        >
          <InfiniteScroll
            dataLength={messages.length}
            next={fetchMessagesPaginated}
            hasMore={hasMore}
            loader={
              <li>
                <div className="col-sm-12 text-center">
                  <img
                    src={spinnerGif}
                    alt="loader"
                    style={{
                      height: "100px",
                    }}
                  />
                </div>
              </li>
            }
            endMessage={
              <li>
                <div className="chat-day-title">
                  <span className="title">Chat Beginning</span>
                </div>
              </li>
            }
            scrollableTarget="messages"
            inverse={true}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            height={470}
          >
            {fetching && (
              <li>
                <div className="conversation-list">
                  <div className="ctext-wrap">
                    {/* TODO: that must be come from the database */}
                    <div className="conversation-name">
                      {nameAi || "Invastor Ai"}
                    </div>
                    <p>
                      <img
                        src={spinnerGif}
                        alt="loader"
                        style={{
                          height: "50px",
                        }}
                      />
                    </p>
                  </div>
                </div>
              </li>
            )}
            {messages &&
              map(messages, message => (
                <li
                  key={"test_k" + message._id}
                  className={message.owner == currentUser.userid ? "right" : ""}
                  style={{ marginRight: "10px" }}
                >
                  <div className="conversation-list">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        href="#"
                        tag="a"
                        className="dropdown-toggle"
                      >
                        <i className="bx bx-dots-vertical-rounded" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={e => copyMsg(e.target)} href="#">
                          Copy
                        </DropdownItem>
                        <DropdownItem href="#">Save</DropdownItem>
                        <DropdownItem href="#">Forward</DropdownItem>
                        <DropdownItem
                          onClick={e => toggle_deleMsg(e.target)}
                          href="#"
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="ctext-wrap">
                      <div className="conversation-name">
                        {/* TODO: that must be come from the database */}
                        {message.owner == currentUser.userid
                          ? currentUser.first_name + " " + currentUser.last_name
                          : nameAi || "Invastor Ai"}
                      </div>
                      <p style={{ whiteSpace: "pre-line", textAlign: "left" }}>
                        {message.message}
                      </p>
                      <p className="chat-time mb-0">
                        <i className="bx bx-time-five align-middle me-1"></i>{" "}
                        {message.time}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className="p-3 chat-input-section">
        <Row>
          <Col>
            <div className="position-relative">
              <textarea
                ref={textareaRef}
                style={{
                  maxHeight: "12rem",
                  minHeight: "38px",
                  resize: "none",
                }}
                type="text"
                value={curMessage}
                onKeyPress={onKeyPress}
                onChange={e => setcurMessage(e.target.value)}
                className="form-control chat-input"
                placeholder={placeholderToInput}
                disabled={fetching}
              />
              <div className="chat-input-links">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <Link to="#">
                      <i
                        className="mdi mdi-emoticon-happy-outline me-1"
                        id="Emojitooltip"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="Emojitooltip"
                      >
                        Emojis
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i
                        className="mdi mdi-file-image-outline me-1"
                        id="Imagetooltip"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="Imagetooltip"
                      >
                        Images
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i
                        className="mdi mdi-file-document-outline"
                        id="Filetooltip"
                      />
                      <UncontrolledTooltip placement="top" target="Filetooltip">
                        Add Files
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col className="col-auto">
            <Button
              type="button"
              color="primary"
              onClick={() => addMessage(currentRoomId, currentUser.name)}
              className="btn btn-primary btn-rounded chat-send w-md "
            >
              <span className="d-none d-sm-inline-block me-2">Send</span>{" "}
              <i className="mdi mdi-send" />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default connect(state => {
  return {
    chat: state.chat,
  }
})(MessagesList)
