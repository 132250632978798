import {
  GENERATE_BLOG,
  GENERATE_BLOG_SUCCESSFUL,
  GENERATE_BLOG_FAILED,
  GET_BLOGS_PAGINATED,
  GET_BLOGS_PAGINATED_SUCCESSFUL,
  GET_BLOGS_PAGINATED_FAILED,
  GET_BLOG_BY_ID,
  GET_BLOG_BY_ID_SUCCESSFUL,
  GET_BLOG_BY_ID_FAILED
} from "./actionTypes"

const initialState = {
  error: null,
  message: null,
  loading: false,
  content: null,
  blogs: [],
}

const blog = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_BLOG:
      state = {
        ...state,
        loading: true,
        error: null,
      }
      break

    case GENERATE_BLOG_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        content: action.payload,
        error: null,
      }
      break

    case GENERATE_BLOG_FAILED:
      state = {
        ...state,
        content: null,
        loading: false,
        error: action.payload,
      }
      break

    case GET_BLOGS_PAGINATED:
      state = {
        ...state,
        loading: true,
        error: null,
      }
      break

    case GET_BLOGS_PAGINATED_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        blogs: action.payload,
        error: null,
      }
      break

    case GET_BLOGS_PAGINATED_FAILED:
      state = {
        ...state,
        blogs: null,
        loading: false,
        error: action.payload,
      }
      break
    //
    case GET_BLOG_BY_ID:
      state = {
        ...state,
        loading: true,
        error: null,
      }
      break

    case GET_BLOG_BY_ID_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        content: action.payload,
        error: null,
      }
      break

    case GET_BLOG_BY_ID_FAILED:
      state = {
        ...state,
        content: null,
        loading: false,
        error: action.payload,
      }
      break
    
    default:
      state = { ...state }
      break
  }
  return state
}

export default blog
