import React from "react"
import { Link, useLocation } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"

// import images
import img1 from "../../assets/images/small/img-2.jpg"

const formatText = (text) => {
  // Define regex patterns for parsing
  const titleRegex = /Title:\s*(.+?)<br>/i;
  const subTitleRegex = /Subtitle:\s*(.+?)<br>/i;
  const introRegex = /Introduction:<br>\s*(.+?)<br>/i;
  const blockQuoteRegex = /Block Quote:<br>\s*(.*?)\s*<br>/i;
  const conclusionRegex = /Conclusion:<br>\s*(.+?)<br>/i;

  // Parse text using regex patterns
  const title = titleRegex.exec(text)[1];
  const introduction = introRegex.exec(text)[1];
  const subTitle = subTitleRegex.exec(text)[1];
  const conclusion = conclusionRegex.exec(text)[1];

  // Remove the matched parts from original text
  const bodyContent = text
    .replace(titleRegex, '')
    .replace(introRegex, '')
    .replace(subTitleRegex, '')
    .replace(conclusionRegex, '');
  
  const firstPart = bodyContent
    .split(blockQuoteRegex)[0]
    .replace(/<br\s*\/?>/gi, '\n\n');
    
  const secondPart = bodyContent
    .split(blockQuoteRegex)[2]
    .replace(/<br\s*\/?>/gi, '\n\n');

  const blockQuote = bodyContent
    .split(blockQuoteRegex)[1]
    .replace(/<br\s*\/?>/gi, '\n');

  const quoteParts = blockQuote.split(' - ');
  const quoteText = quoteParts[0];
  const quoteAuthor = quoteParts[1];

  return {
    title: title, 
    introduction: introduction, 
    subTitle: subTitle,
    firstPart: firstPart,
    secondPart: secondPart,
    quoteText: quoteText,
    quoteAuthor: quoteAuthor,
    conclusion: conclusion
  }
}

const BlogPreview = (props) => {
    const { 
      id = props.id || 0, 
      topic = props.topic || "", 
      date = props.date || "", 
      text = props.text || "", 
      user = props.user || {} 
    } = props;

    const userFullName = user?.first_name + " " + user?.last_name;
    
    const formattedHour = new Date(date).toLocaleString('en-US', { 
      timeZone: 'America/Sao_Paulo', 
      hour12: true, 
      hour: 'numeric', 
      minute: 'numeric' 
    });

    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const formattedTopic = topic.charAt(0).toUpperCase() + topic.slice(1);

    const { 
      title, 
      subTitle, 
      introduction, 
      firstPart,
      secondPart,
      quoteText,
      quoteAuthor,
      conclusion
    } = formatText(text);

  return (
    <React.Fragment>
        <Container fluid>
          <Card>
            <CardBody>
              <div className="pt-3">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div>
                      <div className="text-left">
                        <div> {formattedTopic} </div>
                        <h1 className="my-2" style={{fontWeight:"800"}}> {title} </h1>
                        <h3 className="fw-normal fs-4"> {subTitle} </h3>
                        <p className="text-muted mb-4">
                          <i className="mdi mdi-calendar me-1"></i> {formattedDate}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center my-4">
                        <div>
                          <a className="fw-bold me-2" href="#"> {userFullName} </a>
                          <span>
                            <a href="#">@socialmedia</a>
                            <span> / </span>
                            <time>{formattedHour}<span> GMT-3 • </span>{formattedDate}</time>
                          </span>
                        </div>
                      </div>
                      <div className="my-3">
                        <img
                          src={img1}
                          alt=""
                          className="img-thumbnail mx-auto d-block"
                        />
                      </div>

                      <hr />

                      <div className="mt-4">
                        <div className="text-muted font-size-14">
                          <h5 className="mb-5 text-center" style={{lineHeight:'1.5'}}>{introduction}</h5>
                          <div style={{ whiteSpace: 'pre-line', fontSize: '1rem'}}>
                            {firstPart}
                          </div>
                          <blockquote className="bg-light border border-secondary border-3 rounded p-3 my-4">
                            <p className="mb-0">{quoteText}</p>
                            {quoteAuthor && <footer className="blockquote-footer text-end mt-1">{quoteAuthor}</footer>}
                          </blockquote>
                          <div style={{ whiteSpace: 'pre-line', fontSize: '1rem'}}>
                            {secondPart}
                          </div>
                          <h5>{conclusion}</h5>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
    </React.Fragment>
  )
}

export default BlogPreview
